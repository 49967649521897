li {
    margin-bottom: 0.5rem;
    line-height: 1.3rem;
}

#skills {
    flex: 1;
    width: 100%;
    background-color: var(--dark-blue);
}

.skills-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.skill-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.skill {
    flex-direction: column;
    text-align: center;
    margin: 1rem 1.25rem;
    transition: all 0.3s ease-in-out;
}

.skill div {
    width: 90px;
    height: 90px;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: var(--white);
    transition: all 0.3s ease-in-out;
}

.skill div img {
    width: 50%;
    height: 50%;
}

.skill div p {
    font-weight: 500;
    margin-top: 0.5rem;
}

.title-name {
    color: var(--white);
    display: inline-block;
    margin: 1rem 0.5rem 0.5rem 0;
}
@media screen and (max-width: 900px) {
    .skills-container {
        width: 100%;
        flex-direction: column;
    }
    .skill-list {
        margin-right: 0;
        justify-content: center;
    }

    .skill {
        margin: 1rem;
    }
}

@media screen and (min-width: 2000px) {
    .skill div {
        width: 150px;
        height: 150px;
        margin: 1rem 2rem;
    }
    .skill div p {
        margin-top: 1rem;
    }
}
@media screen and (max-width: 650px) {
    .title-name {
        display: block;
    }
}
@media screen and (max-width: 450px) {
    .skill div {
        width: 70px;
        height: 70px;
    }
}
