#projects {
    flex: 1;
    width: 100%;
    flex-direction: row;
    background-color: var(--blue);
}
#projects h1 {
    margin: 0;
}

.project-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
}

.project-filter .project-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: transparent;
    border: 1px solid var(--green);
    color: var(--grey);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
}

.project-filter .project-filter-item:hover:not(.active-item) {
    background-color: var(--transparent-green);
    color: var(--dark-blue);
}

@media screen and (min-width: 2000px) {
    .project-filter .project-filter-item {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
    }
}
.project-filter-item.active-item {
    transform: translateY(-7px);

    background-color: var(--green);
    color: var(--dark-blue);
    box-shadow: 0 0 20px var(--transparent-green);
}

.project-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.project-portfolio .project-item {
    width: 270px;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: var(--dark-blue);
    color: var(--grey);
    transition: all 0.3s ease;
}

@media screen and (min-width: 700px) {
    .project-portfolio .project-item {
        width: 400px;
        padding: 1.25rem;
    }
}

@media screen and (min-width: 2000px) {
    .project-portfolio .project-item {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
    }
}
@media screen and (max-width: 300px) {
    .project-portfolio .project-item {
        width: 100%;
        margin: 1rem;
    }
}
@media screen and (max-width: 450px) {
    .project-portfolio .project-item {
        margin: 2rem 1rem;
    }
    .project-filter {
        margin: 2rem 1.5rem;
    }
}

.project-portfolio .project-item:has(.project-image:hover) {
    transform: translateY(-6px);
    box-shadow: 0 0 25px var(--transparent-green);
    border: 1px solid var(--transparent-green);
}

.project-portfolio
    .project-item:has(.project-image:hover)
    .bold-text.head-text {
    color: var(--green);
}
.project-image {
    width: 100%;
    height: 230px;
    position: relative;
}
.project-image img {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    object-fit: cover;
}

@media screen and (min-width: 2000px) {
    .project-image {
        height: 350px;
    }
}
@media screen and (min-width: 650px) {
    .head-text.project-title {
        font-size: 2.5rem;
    }
}

.project-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--transparent-green);
    border-radius: 0.25rem;
    opacity: 0;
    transition: all 0.3s ease;
}
.project-hover div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--white);
    margin: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
}
.project-hover div svg {
    width: 50%;
    height: 50%;
}

.project-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
}

.project-content h4 {
    margin-top: 2rem;
    line-height: 1.5rem;
}

.project-content .project-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--dark-blue);
    top: -25px;
}

.project-content .p-text {
    align-self: flex-start;
}
a.deactivated {
    pointer-events: none;
    cursor: default;
}
