#contact {
    background-color: var(--dark-blue);
    width: 100%;
    flex: 1;
}
#contact h1 {
    margin: 0;
}
#contact .social-media {
    visibility: hidden;
}
#contact .social-media-contact .social-media {
    visibility: visible;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 1.5rem;
}

.contact-card a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
}
.contact-card a svg,
#contact .social-media a svg {
    width: 50px;
    height: 50px;
    margin: 0 0.7rem;
}

.contact-card a:hover {
    transform: translateY(-2px);
    color: var(--green);
}

.contact-cards {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.contact-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    padding: 2.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--green);
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.contact-card:has(a:hover) {
    box-shadow: 0 0 15px var(--transparent-green);
}

.contact-button {
    color: var(--gray);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: 0.25rem;
    padding: 1.25rem 1.75rem;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 1.2rem;
}
.contact-button:hover {
    color: var(--green);
    background-color: var(--transparent-green);
}
.contact-card.text-box {
    border: none;
    text-align: center;
    box-shadow: none;
    max-width: 500px;
    padding: 1rem;
}
@media screen and (max-width: 450px) {
    .contact-cards,
    .contact-card {
        width: 70vw;
        min-width: 250px;
    }
    .contact-card {
        padding: 2rem 1rem;
    }
    .contact-cards {
        padding: 0 1rem 2rem;
    }
}
