#home {
    position: relative;
    background-color: var(--blue);
}
.app-header {
    flex: 1;
    padding: 6rem 0;
    height: 100%;
    width: 100%;
    flex-direction: row;
}
.profile-picture {
    border-radius: 50%;
    max-width: 520px;
    max-height: 520px;
}

@media screen and (min-width: 2000px) {
    .app-header {
        padding-top: 8rem;
    }
}

@media screen and (max-width: 1200px) {
    .app-header {
        flex-direction: column;
    }
}
@media screen and (max-width: 900px) {
    .profile-picture {
        width: 80%;
    }
}

.app-header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

@media screen and (max-width: 2000px) {
    .app-header-info {
        width: 100%;
        margin-right: 0rem;
    }
}

.app-header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}

.job-tag,
.greeting-badge {
    padding: 1rem 2rem;
    border: 1px solid var(--green);
    border-radius: 1.75rem;
    flex-direction: row;
    width: auto;
    -webkit-animation: containerPulsar 2s ease-in-out infinite alternate;
    -moz-animation: containerPulsar 2s ease-in-out infinite alternate;
    animation: containerPulsar 2s ease-in-out infinite alternate;
}

@keyframes containerPulsar {
    from {
        box-shadow: 0 0 1px rgba(9, 20, 44, 0.5), 0 0 2px #27204a,
            0 0 5px rgba(0, 255, 255, 0.5), 0 0 6px rgba(9, 20, 44, 0.5);
    }
    to {
        box-shadow: 0 0 4px rgba(9, 20, 44, 0.5),
            0 0 14px rgba(93, 246, 215, 0.6);
    }
}

.greeting-badge div p {
    color: var(--green);
}
.job-tag {
    flex-direction: column;
    margin-top: 3rem;
}
.job-tag p {
    width: 100%;
    text-transform: uppercase;
    text-align: right;
}
.greeting-badge span {
    font-size: 2.5rem;
}
@media screen and (min-width: 2000px) {
    .greeting-badge span {
        font-size: 5rem;
    }
}
@media screen and (max-width: 1200px) {
    .app-header-badge {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1300px) {
    .app-header-badge {
        font-size: 1.5rem;
    }
    .greeting-badge div p {
        font-size: 1.1rem;
    }
}

.app-header-image {
    flex: 1;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    position: relative;
}
.app-header-image img {
    object-fit: contain;
    z-index: 1;
}

.app-header-image .overlay-circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
}

@media screen and (max-width: 1200px) {
    .app-header-image {
        margin: 2rem 0;
    }
}
@media screen and (max-width: 450px) {
    .app-header {
        padding: 4rem 0 0;
    }
    .app-header-badge {
        margin: 0 1rem;
        font-size: 0.9rem;
    }
    .job-tag {
        margin-top: 2rem;
    }
    .app-header-image img {
        margin: 1rem 0.5rem;
    }
    .app-header-image {
        margin: 2rem 0 0.5rem;
    }
}
