#navbar {
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: rgba(9, 20, 44, 0.8);
    position: fixed;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 2;
    border: 1px solid #071025;
}

#navbar div {
    display: flex;
}
#navbar div h4 {
    margin: 30px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}
#navbar div h4:hover,
#navbar div h4:active {
    color: var(--green);
    cursor: pointer;
    transform: translateY(-3px);
}
#navbar .app-navbar .resume h4:hover,
#navbar .app-navbar .resume h4:active {
    transform: none;
}
.icon {
    border: 3px solid var(--green);
    padding: 13px;
    border-radius: 50%;
    margin-left: 2rem;
    color: var(--green);
    align-self: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.icon:hover {
    box-shadow: 0 0 10px var(--transparent-green);
}

@media screen and (max-width: 900px) {
    #navbar .app-navbar {
        display: none;
    }
    .icon {
        padding: 8px;
        border: 2px solid var(--green);
    }
}

.app-navbar-mobile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey);
    color: var(--blue);
    margin-right: 20px;
}
.app-navbar-mobile svg {
    width: 30px;
    height: 30px;
}

.app-navbar-mobile div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    color: var(--grey);
    box-sizing: content-box;

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}
.app-navbar-mobile div a {
    width: 80%;
    text-align: end;
}
.app-navbar-mobile div h4:hover,
.app-navbar-mobile .closing-mobile-menu:hover {
    color: var(--green);
    transform: translateY(-3px);
}

.resume {
    color: inherit;
    text-decoration: none;
}

@media screen and (min-width: 900px) {
    #navbar .app-navbar-mobile {
        display: none;
    }
    .resume {
        height: 45px;
        align-self: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        border: 1px solid var(--green);
        border-radius: 0.25rem;
        transition: all 0.3s ease-in-out;
    }
    .resume:hover {
        background-color: var(--transparent-green);
        color: var(--green);
        transform: translateY(-2px);
    }
}
.app-navbar-mobile .closing-mobile-menu {
    width: 35px;
    height: 35px;
    margin: 1rem 1rem 2.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.mobile-slide-menu {
    width: 100%;
    height: 100%;
    background-color: rgba(9, 20, 44, 0.95);
}
.mobile-slide-menu a h4 {
    font-size: 1.2rem;
}
#navbar .mobile-slide-menu {
    justify-content: center;
}
