:root {
    --blue: #14243e;
    --dark-blue: #09142c;
    --green: #5df6d7;
    --transparent-green: rgba(93, 246, 215, 0.5);
    --technology-green: rgba(93, 246, 215, 0.7);
    --grey: #949db8;
    --white: #e2edfc;
}

.App {
    text-align: left;
    width: 100vw;
    color: var(--grey);
}
.head-text {
    color: var(--white);
    max-width: 600px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.social-media {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    padding: 1rem;
}
.social-media a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.social-media a:hover {
    transform: translateY(-7px);
    color: var(--green);
}

.social-media div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;
}

span.company {
    display: inline-block;
    position: relative;
    color: var(--green);
}
span.company::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--green);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
span.company:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

ul {
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
}
ul li:before {
    content: "»";
    position: absolute;
    left: 0;
    color: var(--green);
}

@media screen and (min-width: 2000px) {
    .social-media div {
        width: 70px;
        height: 70px;

        margin: 0.5rem 0;
    }

    .social-media div svg {
        width: 30px;
        height: 30px;
    }
}

.app-container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
}

.app-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
}

.p-text {
    font-weight: 500;
}
.bold-text {
    font-weight: 900;
}

@media screen and (max-width: 450px) {
    .app-wrapper {
        padding: 4rem 1rem 2rem;
    }
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 1rem;
}

.navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--grey);
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;
}
.navigation-dot:hover {
    background-color: #9ee4d666;
    cursor: pointer;
}

@media screen and (min-width: 2000px) {
    .navigation-dot {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 500px) {
    .navigation,
    .social-media {
        display: none;
    }
}
@media screen and (min-width: 500px) and (max-width: 590px) {
    .app-wrapper {
        padding: 4rem 0rem;
    }
}

#contact .social-media {
    display: none;
}
#contact .social-media-contact .social-media {
    display: block;
}

#about.app-container {
    flex-direction: row;
}
.tech-stack {
    display: flex;
    flex-wrap: wrap;
}
.technology-badge {
    background-color: var(--blue);
    color: var(--technology-green);
    padding: 0.3rem 0.65rem 0.35rem;
    margin: 0.3rem;
    border-radius: 1rem;
}
